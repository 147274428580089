body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

a:hover,
a:focus {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  color: currentColor;
}

a {
  color: currentColor;
}

.container-fluid {
  width: 100%;
  overflow: hidden;
  background: #130e60;
}

@media only screen and (min-width: 768px) {
  .img-responsive {
    min-height: 48vh;
    margin: auto;
  }
}

@media only screen and (min-height: 1600px) {
}
