@import "/../../img/iphone.svg";

#Projects::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 100px 85px;
  border-color: transparent transparent #dda77b transparent;
  background: #945d5e;
  -webkit-transform: rotate(360deg) /* ^improves anti-aliasing */;
}

#Projects {
  background: #dda77b;
  height: auto;
  text-align: left;
  color: #130e60;
  font-family: "Raleway", sans-serif;
}

.Projects-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 10px;
}

.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.SingleProject-title {
  font-size: 2em;
  margin: 10px;
}

.SingleProject-tagline {
  font-size: 1.25em;
  margin: 0 0 10px 0;
}

.Projects-text {
  font-size: 1.25em;
  margin: 10px;
}

.Projects-thumbnail {
  height: auto;
  min-width: 280px;
  margin: 25px auto;
}

@media only screen and (min-width: 768px) {
  .SingleProject-title {
    font-size: 4em;
    margin: 10px;
    padding: 15px 0 0 35px;
  }

  .Projects-header {
    padding: 0 0 0 50px;
    margin: 10px 0;
  }

  .Projects-text {
    padding: 5px 0 0 50px;
    margin: 10px 0;
  }

  .Projects-text:last-child {
    margin-bottom: 2em;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Projects-text {
    font-size: 1.25em;
  }
}

.projectSandboxWrapper {
  color: white;
  background: url("../../img/iphone.svg") right center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  height: 600px;
  width: auto;
  margin: 35px 50px 50px;
}

.projectSandbox {
  transform: scale(0.76);
  transform-origin: 0 0;
  position: absolute;
  top: 93px;
  right: -18px;
  width: 405px;
  height: 618px;
  border: none;
}

@media only screen and (min-height: 1600px) {
  #Projects::before {
    border-width: 0 100vw 16em 12em;
  }
  .Projects-header {
    font-size: 8em;
    margin-top: 0.75em;
  }
  .Projects-text {
    font-size: 3em;
  }
  .SingleProject-title {
    font-size: 8em;
  }
  .SingleProject-tagline {
    font-size: 1.5em;
    margin: 0 0 0.75em 0;
  }
  .projectSandboxWrapper {
    height: 1200px;
    width: auto;
    margin: 50px;
  }

  .projectSandbox {
    transform: scale(1.7);
    transform-origin: 0 0;
    position: absolute;
    top: 9.75em;
    right: 24.75em;
    width: 365px;
    height: 572px;
  }
}
