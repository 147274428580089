.navbar-light {
  border: none;
  background: #faebd7;
  height: 75px;
}

.navbar .navbar-brand {
  color: #351bde;
  font-family: "Londrina Shadow", cursive;
  font-size: 7vmin;
}

@media only screen and (min-width: 992px) {
  .navbar-collapse {
    position: absolute;
    right: 0;
    margin-right: 50px;
  }

  .navbar .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 65px;
    font-size: 2.5em;
  }
}

.navbar-light .navbar-brand:hover {
  color: #351bde;
}

/*  tablet fix  */
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .navbar-light .navbar-brand {
    font-size: 2em;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #351bde;
  font-family: "Raleway", sans-serif;
  margin: 10px;
  transition: font-weight 0.1s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #351bde;
  font-weight: bolder;
}

.navbar-light .navbar-toggler,
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover {
  background: none;
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(53, 27, 222)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-collapse {
  text-align: center;
  font-size: larger;
  max-height: none;
  border: none;
  background: #faebd7;
}

@media only screen and (min-width: 768px) {
  .navbar-collapse {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1600px) {
  .navbar-light {
    height: 12em;
  }

  .navbar-light .navbar-brand {
    font-size: 8em;
    margin: 0.5em 10px;
  }

  .navbar-light .navbar-nav > li > a {
    margin: 0.75em;
    padding: 1em;
  }

  .navbar-fixed-top .navbar-collapse {
    font-size: 3em;
  }
}
