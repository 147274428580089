.project-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
}

.project-icon {
  cursor: pointer;
  height: 1em;
}

.project-icon:not(:first-child):not(:last-child) {
  margin: 10px;
}

.project-icon:first-child {
  margin: 10px 10px 10px 0;
}

.project-icon:last-child {
  margin: 10px 0 10px 10px;
}

.project-icon path {
  fill: #130e60;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-icons {
    margin: 0;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .project-icons {
    margin: 0;
    padding-top: 10px;
  }
  .project-icon path {
    transition: fill 0.3s ease-in-out;
  }

  .project-icon svg {
    transition: transform 0.3s ease-in-out;
  }
  .project-icon:hover svg {
    transform: scale(1.25);
  }
}
