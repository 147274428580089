#Contact::before {
  content: "";
  margin: 0 auto;
  position: relative;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 50vw 0 50vw;
  border-color: #dda77b transparent transparent transparent;
  transform: rotate(360deg);
  /* ^improves anti-aliasing */
}

footer {
  color: #e0a472;
  vertical-align: middle;
  text-align: center;
  font-family: "Raleway", sans-serif;
  padding-bottom: 35px;
}

.Contact-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 25px 10px 10px 10px;
}

.Contact-text {
  font-size: 1.25em;
  margin: 0.75em auto;
  text-align: left;
}

.icon-wrapper {
  width: inherit;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 25px auto;
}

.icon svg {
  height: 3em;
  width: 3em;
}

.icon path {
  transition: 0.3s ease-in-out;
  fill: #dda77b;
}

.icon:hover path,
.icon:focus path {
  fill: #72c19f;
}

.heart {
  color: #945d5e;
  margin: 6px;
  position: relative;
  top: -2px;
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Contact-text {
    font-size: 1em;
  }
}

@media only screen and (min-width: 992px) {
  .Contact-text {
    padding: 5px 50px 5px 70px;
    margin: 10px auto;
    max-width: 700px;
  }
  .icon-wrapper {
    max-width: 30vw;
  }
}

@media only screen and (min-height: 1600px) {
  #Contact::before {
    border-width: 20em 50vw 0 50vw;
  }
  .Contact-header {
    font-size: 8em;
    margin: 1em 0.75em 0;
  }

  .Contact-text {
    font-size: 4em;
    margin: 0.75em auto;
    max-width: 45vw;
  }
  .icon {
    margin: 0px 2em;
  }
  .icon svg {
    height: 4vmax;
    width: 4vmax;
  }
  .madeBy {
    font-size: 4em;
    padding: 1em 0 2em;
  }
}
