@import url(/../../img/iphone.svg);
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

a:hover,
a:focus {
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
  color: currentColor;
}

a {
  color: currentColor;
}

.container-fluid {
  width: 100%;
  overflow: hidden;
  background: #130e60;
}

@media only screen and (min-width: 768px) {
  .img-responsive {
    min-height: 48vh;
    margin: auto;
  }
}

@media only screen and (min-height: 1600px) {
}

.navbar-light {
  border: none;
  background: #faebd7;
  height: 75px;
}

.navbar .navbar-brand {
  color: #351bde;
  font-family: "Londrina Shadow", cursive;
  font-size: 7vmin;
}

@media only screen and (min-width: 992px) {
  .navbar-collapse {
    position: absolute;
    right: 0;
    margin-right: 50px;
  }

  .navbar .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 65px;
    font-size: 2.5em;
  }
}

.navbar-light .navbar-brand:hover {
  color: #351bde;
}

/*  tablet fix  */
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .navbar-light .navbar-brand {
    font-size: 2em;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #351bde;
  font-family: "Raleway", sans-serif;
  margin: 10px;
  -webkit-transition: font-weight 0.1s ease-in-out;
  transition: font-weight 0.1s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #351bde;
  font-weight: bolder;
}

.navbar-light .navbar-toggler,
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover {
  background: none;
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(53, 27, 222)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-collapse {
  text-align: center;
  font-size: larger;
  max-height: none;
  border: none;
  background: #faebd7;
}

@media only screen and (min-width: 768px) {
  .navbar-collapse {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 1600px) {
  .navbar-light {
    height: 12em;
  }

  .navbar-light .navbar-brand {
    font-size: 8em;
    margin: 0.5em 10px;
  }

  .navbar-light .navbar-nav > li > a {
    margin: 0.75em;
    padding: 1em;
  }

  .navbar-fixed-top .navbar-collapse {
    font-size: 3em;
  }
}

#Home {
  background: #faebd7;
  height: auto;
  text-align: left;
  color: #351bde;
  padding: 80px 15px 10px;
  overflow-y: visible;
  font-family: "Londrina Shadow", cursive;
}

.Hero-illustration {
  padding: 10px 25px;
  min-height: 48vh;
  height: auto;
}

.Hero-illustration img {
  max-height: 530px;
}

.Hero-text {
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 10px 0;
}

@media only screen and (min-width: 992px) {
  #Home {
    padding: 80px 25px 10px 25px;
  }

  .Hero-text {
    font-size: 2.5em;
    margin: 0 0 0 25px;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Hero-text {
    font-size: 2em;
  }
}

.tablet-Hero-illustration {
  width: 100%;
  padding-top: 25px;
  min-height: 0;
}

@media only screen and (min-height: 1600px) {
  #Home {
    padding-top: 14em;
  }
  .Hero-illustration img {
    max-height: 40vw;
  }
  .Hero-text {
    font-size: 6em;
  }
}

/* triangle! */
#About::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 85px 85px 95vw;
  border-color: transparent transparent #945d5e transparent;
  background: #faebd7;
  -webkit-transform: rotate(360deg);
  /* ^improves anti-aliasing */
}

#About {
  padding-bottom: 25px;
  background: #945d5e;
  height: auto;
  text-align: left;
  color: #faebd7;
  font-family: "Raleway", sans-serif;
}

.About-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 10px;
}

.About-text {
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 10px;
  margin: 0px;
}

@media only screen and (min-width: 768px) {
  #About::before {
    width: 100%;
  }

  .About-header {
    padding: 35px 0 0 50px;
    margin: 10px 0;
  }

  .About-text {
    padding: 5px 50px;
    margin: 10px 0;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .About-text {
    font-size: 1.25em;
    z-index: 10;
    position: relative;
    line-height: 1.25em;
  }
}

.About-illustration {
  margin-top: 15px;
  padding: 0 35px;
}

.me {
  padding-top: 60px;
}

.About-mobile-illustration {
  z-index: 10;
  position: relative;
  padding-top: 15px;
}

.illustration {
  width: 90vw;
  margin: 0 auto;
}

@media only screen and (min-height: 1600px) {
  #About::before {
    border-width: 0 10em 18em 95vw;
  }
  .About-header {
    font-size: 8em;
    margin-top: 0.75em;
  }
  .About-text {
    font-size: 4em;
  }
}

.project-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
}

.project-icon {
  cursor: pointer;
  height: 1em;
}

.project-icon:not(:first-child):not(:last-child) {
  margin: 10px;
}

.project-icon:first-child {
  margin: 10px 10px 10px 0;
}

.project-icon:last-child {
  margin: 10px 0 10px 10px;
}

.project-icon path {
  fill: #130e60;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-icons {
    margin: 0;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .project-icons {
    margin: 0;
    padding-top: 10px;
  }
  .project-icon path {
    -webkit-transition: fill 0.3s ease-in-out;
    transition: fill 0.3s ease-in-out;
  }

  .project-icon svg {
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  .project-icon:hover svg {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

#Projects::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 100px 85px;
  border-color: transparent transparent #dda77b transparent;
  background: #945d5e;
  -webkit-transform: rotate(360deg) /* ^improves anti-aliasing */;
}

#Projects {
  background: #dda77b;
  height: auto;
  text-align: left;
  color: #130e60;
  font-family: "Raleway", sans-serif;
}

.Projects-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 10px;
}

.container-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.SingleProject-title {
  font-size: 2em;
  margin: 10px;
}

.SingleProject-tagline {
  font-size: 1.25em;
  margin: 0 0 10px 0;
}

.Projects-text {
  font-size: 1.25em;
  margin: 10px;
}

.Projects-thumbnail {
  height: auto;
  min-width: 280px;
  margin: 25px auto;
}

@media only screen and (min-width: 768px) {
  .SingleProject-title {
    font-size: 4em;
    margin: 10px;
    padding: 15px 0 0 35px;
  }

  .Projects-header {
    padding: 0 0 0 50px;
    margin: 10px 0;
  }

  .Projects-text {
    padding: 5px 0 0 50px;
    margin: 10px 0;
  }

  .Projects-text:last-child {
    margin-bottom: 2em;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Projects-text {
    font-size: 1.25em;
  }
}

.projectSandboxWrapper {
  color: white;
  background: url(/static/media/iphone.7d2dc59b.svg) right center no-repeat;
  background-size: contain;
  height: 600px;
  width: auto;
  margin: 35px 50px 50px;
}

.projectSandbox {
  -webkit-transform: scale(0.76);
          transform: scale(0.76);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  position: absolute;
  top: 93px;
  right: -18px;
  width: 405px;
  height: 618px;
  border: none;
}

@media only screen and (min-height: 1600px) {
  #Projects::before {
    border-width: 0 100vw 16em 12em;
  }
  .Projects-header {
    font-size: 8em;
    margin-top: 0.75em;
  }
  .Projects-text {
    font-size: 3em;
  }
  .SingleProject-title {
    font-size: 8em;
  }
  .SingleProject-tagline {
    font-size: 1.5em;
    margin: 0 0 0.75em 0;
  }
  .projectSandboxWrapper {
    height: 1200px;
    width: auto;
    margin: 50px;
  }

  .projectSandbox {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    position: absolute;
    top: 9.75em;
    right: 24.75em;
    width: 365px;
    height: 572px;
  }
}

#Contact::before {
  content: "";
  margin: 0 auto;
  position: relative;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 50vw 0 50vw;
  border-color: #dda77b transparent transparent transparent;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  /* ^improves anti-aliasing */
}

footer {
  color: #e0a472;
  vertical-align: middle;
  text-align: center;
  font-family: "Raleway", sans-serif;
  padding-bottom: 35px;
}

.Contact-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 25px 10px 10px 10px;
}

.Contact-text {
  font-size: 1.25em;
  margin: 0.75em auto;
  text-align: left;
}

.icon-wrapper {
  width: inherit;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 25px auto;
}

.icon svg {
  height: 3em;
  width: 3em;
}

.icon path {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  fill: #dda77b;
}

.icon:hover path,
.icon:focus path {
  fill: #72c19f;
}

.heart {
  color: #945d5e;
  margin: 6px;
  position: relative;
  top: -2px;
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Contact-text {
    font-size: 1em;
  }
}

@media only screen and (min-width: 992px) {
  .Contact-text {
    padding: 5px 50px 5px 70px;
    margin: 10px auto;
    max-width: 700px;
  }
  .icon-wrapper {
    max-width: 30vw;
  }
}

@media only screen and (min-height: 1600px) {
  #Contact::before {
    border-width: 20em 50vw 0 50vw;
  }
  .Contact-header {
    font-size: 8em;
    margin: 1em 0.75em 0;
  }

  .Contact-text {
    font-size: 4em;
    margin: 0.75em auto;
    max-width: 45vw;
  }
  .icon {
    margin: 0px 2em;
  }
  .icon svg {
    height: 4vmax;
    width: 4vmax;
  }
  .madeBy {
    font-size: 4em;
    padding: 1em 0 2em;
  }
}

