#Home {
  background: #faebd7;
  height: auto;
  text-align: left;
  color: #351bde;
  padding: 80px 15px 10px;
  overflow-y: visible;
  font-family: "Londrina Shadow", cursive;
}

.Hero-illustration {
  padding: 10px 25px;
  min-height: 48vh;
  height: auto;
}

.Hero-illustration img {
  max-height: 530px;
}

.Hero-text {
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 10px 0;
}

@media only screen and (min-width: 992px) {
  #Home {
    padding: 80px 25px 10px 25px;
  }

  .Hero-text {
    font-size: 2.5em;
    margin: 0 0 0 25px;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .Hero-text {
    font-size: 2em;
  }
}

.tablet-Hero-illustration {
  width: 100%;
  padding-top: 25px;
  min-height: 0;
}

@media only screen and (min-height: 1600px) {
  #Home {
    padding-top: 14em;
  }
  .Hero-illustration img {
    max-height: 40vw;
  }
  .Hero-text {
    font-size: 6em;
  }
}
