/* triangle! */
#About::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 85px 85px 95vw;
  border-color: transparent transparent #945d5e transparent;
  background: #faebd7;
  -webkit-transform: rotate(360deg);
  /* ^improves anti-aliasing */
}

#About {
  padding-bottom: 25px;
  background: #945d5e;
  height: auto;
  text-align: left;
  color: #faebd7;
  font-family: "Raleway", sans-serif;
}

.About-header {
  font-size: 7vmin;
  font-family: "Londrina Shadow", cursive;
  margin: 10px;
}

.About-text {
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 10px;
  margin: 0px;
}

@media only screen and (min-width: 768px) {
  #About::before {
    width: 100%;
  }

  .About-header {
    padding: 35px 0 0 50px;
    margin: 10px 0;
  }

  .About-text {
    padding: 5px 50px;
    margin: 10px 0;
  }
}

/*  iPhone <= 5 fix (the IE of devices...)  */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
  .About-text {
    font-size: 1.25em;
    z-index: 10;
    position: relative;
    line-height: 1.25em;
  }
}

.About-illustration {
  margin-top: 15px;
  padding: 0 35px;
}

.me {
  padding-top: 60px;
}

.About-mobile-illustration {
  z-index: 10;
  position: relative;
  padding-top: 15px;
}

.illustration {
  width: 90vw;
  margin: 0 auto;
}

@media only screen and (min-height: 1600px) {
  #About::before {
    border-width: 0 10em 18em 95vw;
  }
  .About-header {
    font-size: 8em;
    margin-top: 0.75em;
  }
  .About-text {
    font-size: 4em;
  }
}
